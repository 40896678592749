.layerswitcher__container {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 16px;
  right: 25px;
  z-index: 1;
}

.layerswitcher__container--active {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 16px;
  right: 366px;
  z-index: 1;
}

.gate__btn--menu {
  color: white;
  background-color: $g16-color;
}

.gate__btn--menu:hover,
.gate__btn--menu:active {
  background-color: $g16-color;
}

.layerswitcher__content--active {
  background-color: white;
  position: absolute;
  right: 0px;
  top: 40px;
  width: 350px;
  max-height: 525px;
  overflow-y: scroll;
}


.ol-layerswitcher .panel li label, .ol-layerswitcher .layerswitcher-opacity {
  max-width: 280px!important;
}

.ol-layerswitcher .layerswitcher-opacity {
  width: 280px!important;
}

.layerswitcher__content--hidden {
  display: none;
}


.ol-layerswitcher .layerswitcher-opacity .layerswitcher-opacity-cursor, 
.ol-layerswitcher .layerswitcher-opacity .layerswitcher-opacity-cursor:before {
  background: rgba(0, 135, 213,0.5)!important;
}

.ol-layerswitcher .layerswitcher-opacity {
  border: 1px solid $g16-color!important;
  background: linear-gradient(to right, rgba(0, 135, 213,0), rgba(0, 135, 213,0.6))!important;
}

.ol-layerswitcher [type="checkbox"] + label:before {
  border: 2px solid $g16-color!important;
}

.ol-layerswitcher [type="checkbox"]:checked + label:after {
  border-color: $g16-color!important;
}

.ol-layerswitcher .layerup {
  background-color: $g16-color!important;
}
