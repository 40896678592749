.menu__container {
  margin: auto;
  position: relative;
  top: 25vh;
  width: 550px;
}

.menu__button {
  padding-left: 0rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 700;
  background-color: white;
  border: solid 1px rgba(57, 63, 72, 0.3);
  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}

.menu__button:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.menu__button a {
  color: unset;
  display: block;
  width: 100%;
  height: 100%;
}

.menu__button a:hover {
  text-decoration: unset;
}
