.map-container {
  width: 100%;
  height: 100vh;
}

.ol-scale-line {
  bottom: 15px !important;
  left: unset;
  right: 365px;
  background-color: $g16-color;
}

.ol-zoom {
  top: unset;
  left: unset;
  bottom: 50px;
  right: 365px;
}

.ol-control.ol-legend {
  top: unset;
  left: unset;
  bottom: 115px;
  right: 365px;
}

.ol-control.ol-legend button.ol-closebox:before {
  color: $g16-color!important;
  background-color: unset!important;
}

.ol-zoom-in,
.ol-zoom-out,
.ol-control button {
  background-color: $g16-color;
}

.ol-zoom-in:hover,
.ol-zoom-out:hover,
.ol-control button:hover {
  background-color: $g16-color;
}

.ol-control.ol-legend {
  z-index: 0;
}


.ol-legend:not(.ol-collapsed){
  background-color: rgba(255,255,255,1);
  max-height: 510px;
  overflow-y: scroll;
}
.ol-legend:hover:not(.ol-collapsed) {
  background-color: rgba(255,255,255,1);
}


.ol-control {
  padding: unset!important;
}
