.loader:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 5px solid #ccc;
    border-top-color: $g16-color;
    animation: spinner 0.6s linear infinite;
  }
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

.loader {
  z-index: 99;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255,255,255,0.9);

}