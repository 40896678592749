.search__container {
  position: absolute;
  top: 16px;
  right: 75px;
  z-index: 1;
}

.search__container--active {
  position: absolute;
  top: 16px;
  right: 478px;  
  height: 40px;
  z-index: 1;
}

.gate__btn--search {
  color: white;
  background-color: $g16-color;
}

.gate__btn--search:hover {
  background-color: $g16-color;
}
