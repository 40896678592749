.attribute-tab__panel {
  overflow: scroll;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 350px;
  background-color: white;
  -webkit-box-shadow: 0 -5px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 -5px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 -5px 5px rgba(57, 63, 72, 0.3);
}

.attribute-tab__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}

.attribute-tab__header-atlas {
  flex-grow: 3;
  text-align: center;
}

.attribute-tab__header-atlas h5 {
  margin: 0px;
  font-size: 0.9rem;
}

.attribute-tab__close, .attribute-tab__pin {
  text-align: end;
}

.attribute-tab__pin {
  margin-right: 1rem;
}

.attribute-tab__close-btn, .attribute-tab__pin-btn {
  // remove style
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  // new style
  color: $g16-color;
  font-weight: 700;
  font-size: 0.8rem;
}

.attributes__container {
  margin: 0.5rem;
  font-size: 0.8rem;
}
