.header-container{
  background-color: white;
  z-index: 2;
  width: 100%;
  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}

.header-container--viewer {
  background-color: white;
  width: 100%;
}

.header-container--viewer img {
  height: 40px;
  margin: auto;
}

.header-container--viewer .dropdown {
  margin: auto;
  margin-top: 0.5rem;
}

.low-opacity {
  opacity: 0.9;
}

.user__dropdown,
.user__dropdown:hover,
.user__dropdown:active {
  color: $g16-color !important;
  background: unset !important;
  border: unset !important;
}

.navbar img:hover {
  cursor: pointer;
}
