.cockpit-tab__panel {
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;
  position: absolute;
  right: 0;
  height: 100%;
  width: 350px;
  background-color: white;
  -webkit-box-shadow: 0 -5px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 -5px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 -5px 5px rgba(57, 63, 72, 0.3);
}

.cockpit-tab__header {
  margin-top: 0.5rem;
}

.cockpit-tab__header h5 {
  margin: 0px;
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.5;
}

.cockpit-tab__atlas {
  display: flex;
}
.cockpit-tab__atlas select {
  border: unset;
  text-align-last: center;
  font-size: 0.9rem;
}
.cockpit-tab__btn {
  // remove style
  background: none;
  color: inherit;
  border: none;
  padding: 0 1rem;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  // new style
  color: $g16-color;
  font-weight: 700;
  font-size: 1.3rem;
}

.cockpit-tab__btn:focus {
  outline: unset;
}

.attributes__worksites div:not(:first-child){
  border-top: 1px solid rgba(0,0,0,.1);
}

.attributes__worksites > div {
  padding-top: 1rem!important;
  padding-bottom: 1rem!important;
}


// set overview map
#map-overview {
  width:350px;
  height: 150px;
}

.ol-overviewmap {
  left: unset!important;
  bottom: unset!important;
  height: 150px!important;
}

.ol-overviewmap canvas {
  margin-top: 10px;
  margin-left: -10px;
}

div.ol-custom-overviewmap div.ol-overviewmap-map {
  border: none;
  width:350px;
  height: 150px;
}

div.ol-custom-overviewmap div.ol-overviewmap-box {
  border: 2px solid red;
}

.select-css {
	display: block;
	font-size: 16px;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #aaa;
	border-radius: .5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}

.select-css:active, .select-css:focus{
  border:none;
  box-shadow: unset;
  outline: none;
}
.select-css::-ms-expand {
	display: none;
}

.select-css option {
	font-weight:normal;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
	background-position: left .7em top 50%, 0 0;
	padding: .6em .8em .5em 1.4em;
}

/* Disabled styles */
.select-css:disabled, .select-css[aria-disabled=true] {
	color: graytext;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
}
.select-css:disabled:hover, .select-css[aria-disabled=true] {
	border-color: #aaa;
}